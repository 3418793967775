import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import LocalStorageTTL from './localStorage'
import { featuresInterface } from './sharedInterfacesAndTypes'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import moment from 'moment'
import { toastErrors } from '../components/ToastErrors/ToastErrors'
import labelsJson from './labels.json';

import findLast from 'lodash/findLast'
import { ActivationDetails } from './ActivationDetails'
import { flatMap } from 'lodash'

const labels = labelsJson as { [key: string]: string };

const ZONES_ORDER = [
  { name: 'DRA-P', type: 'DRAP', order: 1 },
  { name: 'EP TS', type: 'EPTS', order: 2 },
  { name: 'EP MRT', type: 'EPMRT', order: 3 },
  { name: 'DRA-R', type: 'DRA-R', order: 4 },
  { name: 'DRA-RL', type: 'DRA-RL', order: 5 },
  { name: 'DRA-RM', type: 'DRA-RM', order: 6 },
  { name: 'DRA-RH ', type: 'DRA-RH', order: 7 },
  { name: 'EP R', type: 'EPR', order: 8 },
  { name: 'EP D', type: 'EPD', order: 9 },
  { name: 'EPP', type: 'EPP', order: 10 },
  { name: 'EP TR', type: 'EPTR', order: 11 },
  { name: 'CTR 1 KM', type: 'CTR1KM', order: 12 },
  { name: 'RPA', type: 'RPA', order: 13 },
  { name: 'CTR 6 KM', type: 'CTR6KM', order: 14 },
  { name: 'CTR', type: 'CTR', order: 15 },
  { name: 'MCTR 2 KM', type: 'MCTR2KM', order: 16 },
  { name: 'MCTR', type: 'MCTR', order: 17 },
  { name: 'ATZ 1 KM', type: 'ATZ1KM', order: 18 },
  { name: 'DRA-I', type: 'DRA-I', order: 19 },
  { name: 'LPR/HEMS', type: 'LPR/HEMS', order: 20 },
  { name: 'NW', type: 'NW', order: 21 },
  { name: 'ADIZ', type: 'ADIZ', order: 22 },
  { name: 'AREA', type: 'AREA', order: 23 },
  { name: 'RMZ', type: 'RMZ', order: 24 },
  { name: 'FIS', type: 'FIS', order: 25 },
  { name: 'TMA', type: 'TMA', order: 26 },
  { name: 'MTMA', type: 'MTMA', order: 27 },
  { name: 'EP TF', type: 'EPTF', order: 28 },
]
export const flightForbid = ['DRA-P', 'DRAP']

export const flightPeriodForbid = ['TSA', 'MRT', 'TFR', 'TMA', 'MTMA']

const zonesActiveH24 = ['DRAR', 'DRAP', 'DRAI', 'DRARH', 'DRARM', 'DRARL']
const zonesRequiringOnlyWebcatActivations = ['TRA', 'TSA', 'MRT', 'MATZ', 'D', 'NW', 'TFR', 'R']
//const airacZones = ['AREA', 'ADIZ', 'VLOS', 'BVLOS', 'ATZ', 'MATZ', 'CTR', 'MCTR', 'D', 'EA', 'MRT', 'NW', 'P', 'R', 'RMZ', 'TFR', 'TMA', 'MTMA', 'TRA','TSA'];

const detectIfAixmActivityCanAlsoBeChecked = function (zone: any) {
  return !zonesRequiringOnlyWebcatActivations.includes(zone.type)
}

export function checkIsNotFlightAllowed(features: Array<featuresInterface>, max_height: number) {
  const firstNotAllowed = find(features, (feature) => {
    return checkIsNotFlightAllowedInSpecificZone(feature, max_height)
  })
  return !isEmpty(firstNotAllowed)
}

function checkIfTimeIsBetweenRange({ start, stop }: { start: string; stop: string }) {
  return moment().isBetween(start, stop, undefined, '[)')
}

function checkIfHeightIsBetweenRange({
  min,
  flightData_max_height,
}: {
  min: number
  max: number
  flightData_max_height: number
}) {
  let isBetween = false
  if (flightData_max_height >= min) isBetween = true
  return isBetween
}

function checkIfTimeIsBetweenAllRanges(activation: featuresInterface['activation'], flightData_max_height: number) {
  let isBetween = false

  if (!isEmpty(activation)) {
    activation.forEach(({ start, stop, min, max }) => {
      if (
        checkIfTimeIsBetweenRange({ start, stop }) &&
        checkIfHeightIsBetweenRange({ min, max, flightData_max_height })
      ) {
        isBetween = true
      }
    })

    return isBetween
  }

  return isBetween
}

export function isZoneActiveNEW(
  zoneActiveData: {
    zone: {
      geojson: any
      name: string
      etc: { status: string; master: string }
      type: string
      acts: any
      start: string
      stop: string
    }
    zoneNotams: Array<any>
    zoneActivations: Array<any>
    zoneTimeSlices: Array<any>
  },
  when: moment.Moment
): ActivationDetails {
  if (!isEmpty(zoneActiveData.zone?.etc?.master)) {
    throw new Error(
      `Zone ${zoneActiveData.zone.name} (zoneActiveData.zone.type) has activity of master ${zoneActiveData.zone?.etc?.master}`
    )
  }

  const nonstopActivity = isZoneAlwaysActive(zoneActiveData.zone)

  if (nonstopActivity !== ActivationDetails.NO_ACTIVATION) {
    return nonstopActivity
  }

  const notamActivity = isActivatedByNotam(zoneActiveData.zone, zoneActiveData.zoneNotams, when)

  if (notamActivity !== ActivationDetails.NO_ACTIVATION) {
    return notamActivity
  }

  const webCatActivity = isActivatedByWebCat(zoneActiveData.zone, zoneActiveData.zoneActivations, when)

  if (webCatActivity !== ActivationDetails.NO_ACTIVATION) {
    return webCatActivity
  }

  if (detectIfAixmActivityCanAlsoBeChecked(zoneActiveData.zone)) {
    const aixmAcitivity = isActivatedByAixm(zoneActiveData.zone, zoneActiveData.zoneTimeSlices, when)

    if (aixmAcitivity !== ActivationDetails.NO_ACTIVATION) {
      return aixmAcitivity
    }
  }

  return ActivationDetails.NO_ACTIVATION
}

const isZoneAlwaysActive = function (zone: { name: string; type: string; acts: any }): ActivationDetails {
  const hasH24FlagSet = zone?.acts && zone?.acts?.H24
  const h24ActiveByType = zonesActiveH24.includes(zone.type)
  const isActive = h24ActiveByType || hasH24FlagSet

  if (isActive) {
    return new ActivationDetails(isActive, isActive, undefined, undefined, undefined)
  }

  return ActivationDetails.NO_ACTIVATION
}

const isActivatedByNotam = function (
  zone: {
    geojson: any
    name: string
    etc: { status: string }
    type: string
    acts: any
    start: string
    stop: string
  },
  zoneNotams: Array<any>,
  when: moment.Moment
): ActivationDetails {
  const hasNotam = !isEmpty(zoneNotams)
  const lastZoneNotam = hasNotam ? zoneNotams[0] : undefined
  const hasNotamActivations = hasNotam && !isEmpty(lastZoneNotam.notamActivations)

  if (!hasNotam) {
    return ActivationDetails.NO_ACTIVATION
  }

  if (hasNotamActivations) {
    // TODO: suspected, why we override activation (min, max) ??? (verify)
    lastZoneNotam.notamActivations = lastZoneNotam.notamActivations.map((activation: any) => {
      activation.min = +lastZoneNotam.f
      activation.max = +lastZoneNotam.g
      return activation
    })

    const zoneNotamActivationsThisDay = lastZoneNotam.notamActivationsThisDay
    const recentZoneNotamActivation = findLast(zoneNotamActivationsThisDay, (znAct: any) => {
      return when.valueOf() >= moment(znAct.start).valueOf() && when.valueOf() < moment(znAct.stop).valueOf()
    })
    const isActive = !isEmpty(recentZoneNotamActivation)

    return new ActivationDetails(isActive, false, zoneNotamActivationsThisDay, undefined, undefined)
  }

  if (lastZoneNotam?.d === 'H24' && !!lastZoneNotam?.b && !!lastZoneNotam?.c) {
    const isActive =
      when.valueOf() >= moment(lastZoneNotam.b).valueOf() && when.valueOf() < moment(lastZoneNotam.c).valueOf()
    return new ActivationDetails(isActive, true, undefined, undefined, undefined)
  }

  // console.warn(`Zone ${zone.name}(${zone.type}) has neither notam activation nor notam H24 flag`, lastZoneNotam)

  return new ActivationDetails(false, false, undefined, undefined, undefined)
}

const isActivatedByWebCat = function (
  zone: {
    geojson: any
    name: string
    etc: { status: string }
    type: string
    acts: any
    start: string
    stop: string
  },
  zoneActivations: Array<any>,
  when: moment.Moment
): ActivationDetails {
  const hasWebCatActivations = !isEmpty(zoneActivations)

  if (hasWebCatActivations) {
    const startOfDay = moment(when).startOf('days')
    const startOfNextDay = moment(when).add(1, 'days').startOf('day')
    const webCatActThisDay = filter(zoneActivations, (wcAct: any) => {
      const startTm = moment(wcAct.start)
      const stopTm = moment(wcAct.stop)

      return startTm.valueOf() < startOfNextDay.valueOf() && stopTm.valueOf() > startOfDay.valueOf()
    })
    const lastWebCatAct = findLast(webCatActThisDay, (wcAct: any) => {
      return when.valueOf() >= moment(wcAct.start).valueOf() && when.valueOf() < moment(wcAct.stop).valueOf()
    })

    const isActive = !isEmpty(lastWebCatAct)

    return new ActivationDetails(isActive, false, undefined, webCatActThisDay, undefined)
  }

  return ActivationDetails.NO_ACTIVATION
}

const isActivatedByAixm = function (
  zone: {
    geojson: any
    name: string
    etc: { status: string }
    type: string
    acts: any
    start: string
    stop: string
  },
  zoneTimeSlices: Array<any>,
  when: moment.Moment
): ActivationDetails {
  const hasTimeSlice = !isEmpty(zoneTimeSlices)

  if (hasTimeSlice) {
    const activeTimeSlices = filter(zoneTimeSlices, (tms: any) => {
      return when.valueOf() >= moment(tms.start).valueOf() && when.valueOf() < moment(tms.stop).valueOf()
    })
    const activeTimeSlicesActivations = filter(
      flatMap(activeTimeSlices, (tms: any) => {
        return tms.aixmActivations || []
      }),
      (tsAct: any) => {
        return when.valueOf() >= moment(tsAct.start).valueOf() && when.valueOf() < moment(tsAct.stop).valueOf()
      }
    )
    const startOfDay = moment(when).startOf('days')
    const startOfNextDay = moment(when).add(1, 'days').startOf('day')
    const activeTimeSlicesActivationsThisDay = filter(activeTimeSlicesActivations, (tsAct: any) => {
      const startTm = moment(tsAct.start)
      const stopTm = moment(tsAct.stop)

      return startTm.valueOf() < startOfNextDay.valueOf() && stopTm.valueOf() > startOfDay.valueOf()
    })

    const isActive = !isEmpty(activeTimeSlicesActivations)
    const h24Active = isActive && activeTimeSlicesActivations[0]?.etc?.dab?.note === 'ANY'

    return new ActivationDetails(isActive, h24Active, undefined, undefined, activeTimeSlicesActivationsThisDay)
  }

  if (isEmpty(zone.start) || isEmpty(zone.stop)) {
    return ActivationDetails.NO_ACTIVATION
  }

  const isActive = when.valueOf() >= moment(zone.start).valueOf() && when.valueOf() < moment(zone.stop).valueOf()
  const h24Active = isActive && zone?.acts && zone?.acts?.H24

  return new ActivationDetails(isActive, h24Active, undefined, undefined, undefined)
}

export function checkIsNotFlightAllowedInSpecificZone(feature: featuresInterface, max_height: number) {
  const { type } = feature.properties.data
  const { activation, properties } = feature
  const storedCheckInData = LocalStorageTTL.get('checkInData')
  return (
    flightForbid.includes(type) ||
    (flightPeriodForbid.includes(type) && checkIfTimeIsBetweenAllRanges(activation, max_height)) ||
    (flightPeriodForbid.includes(type) &&
      properties.data.notam &&
      checkIfTimeIsBetweenAllRanges(properties.data.notam.notamActivations, max_height)) ||
    ((storedCheckInData?.flight_category === 'OPEN' || storedCheckInData?.flight_category === undefined) &&
      type === 'CTR1KM') //special case for CTR1KM when checkInData is not saved
  )
}

export const replaceSubCategory = (subcategory: string) => {
  if (subcategory === 'Zezwolenie ULC') return 'EXEMPT_BY_ULC'
  else if (subcategory === 'Klub modelarski') return 'MODELING_CLUB'
  else if (subcategory === '') return 'CERT'
  else return subcategory
}

export const getZonesOrder = (features: any) => {
  features.forEach((item: { order: number; type: string | string[]; name: string | string[] }) => {
    const zoneOrder = find(ZONES_ORDER, (zoneOrder) => {
      return (
        (item.type?.indexOf(zoneOrder.type) !== -1 ||
          item.name?.indexOf(zoneOrder.name) !== -1 ||
          item.name?.indexOf(zoneOrder.type) !== -1) &&
        zoneOrder
      )
    })
    if (zoneOrder) {
      //@ts-ignore
      item.order = zoneOrder.order
    }
  })
  //@ts-ignore
  return sortBy(features, ['order'])
}

export const metersToFeet = (value: number) => {
  return Math.round(value * 3.2808399)
}
export const feetsToMeters = (value: number) => {
  return Math.round(value * 0.3048)
}

export const checkValidationErrors: Function = (response: any) => {
  const fields = response
  const errorsArray: any = []

  for (const property in fields) {
    const field = fields[property]
    if (field[0]?.valid === false && field[0]?.msg) {
      errorsArray.push(labels[field[0].msg] ? labels[field[0].msg] : field[0].msg)
    } else if (field[1]?.valid === false && field[0]?.msg) {
      errorsArray.push(labels[field[1].msg] ? labels[field[1].msg] : field[1].msg)
    }
  }
  toastErrors(errorsArray)
}

export const getNotamsPerZone: Function = (allNotams: Array<any>): Object => {
  const notamsPerZone = allNotams.reduce((acc: any, n: any) => {
    acc[n.zonename] = acc[n.zonename] || []
    acc[n.zonename].push(n)
    return acc
  }, {})

  return notamsPerZone
}

export const getWebCatActivationsPerZone: Function = (allWebCatActivations: Array<any>): Object => {
  const webCatActPerZone = allWebCatActivations.reduce((acc: any, wcAct: any) => {
    acc[wcAct.designator] = acc[wcAct.designator] || []
    acc[wcAct.designator].push(wcAct)
    return acc
  }, {})

  return webCatActPerZone
}

export const getTimeSlicesPerZone: Function = (allTimeSlices: Array<any>): Object => {
  const timeSlicesPerZone = allTimeSlices.reduce((acc: any, ts: any) => {
    acc[ts.designator] = acc[ts.designator] || []
    acc[ts.designator].push(ts)
    return acc
  }, {})

  return timeSlicesPerZone
}

export const saveLocalization = (lat: number, lon: number) => {
  LocalStorageTTL.set('localization', { lat, lon }, 3600000 * 24 * 28)
}
export const getLocalization = () => {
  return LocalStorageTTL.get('localization')
}

export const asyncSleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
