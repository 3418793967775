import { FC, useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { modalPropInterface } from '../../utilities/modalInterfaces'
import { Document, Page } from 'react-pdf'
import throttle from 'lodash/throttle'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

const CustomManualPdf: FC<modalPropInterface> = (props) => {
  const [numPages, setNumPages] = useState(null)
  const [initialWidth, setInitialWidth] = useState<any>(null)
  const { t } = useTranslation()

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  const pdfWrapper = useRef<any>(null)

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper?.current?.getBoundingClientRect().width)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000))
    setPdfSize()
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000))
    }
  }, [])
  if (!props.uniqueProps?.filePath) {
    console.error('filepath for pdf is empty')
    return null
  }
  const {
    filePath,
    downloadText = t('open_pdf_format'),
    downloadTextPosition = 'top',
  } = props.uniqueProps

  const DownloadPdfLink: FC<{ text: string }> = ({ text }) => {
    return (
      <Button style={{ paddingBottom: '20px' }}>
        <a href={filePath} target="_blank" rel="noreferrer" className="no-decoration-link" >
          {text}
        </a>
      </Button>
    )
  }

  return (
    <DialogContent>
      <div
        id="row"
        style={{
          height: '100vh',
          display: 'flex',
        }}>
        <div id="placeholderWrapper" style={{ height: '100vh' }} />
        <div id="pdfWrapper" style={{ width: '90vw' }} ref={pdfWrapper}>
          {downloadText && downloadTextPosition === 'top' && <DownloadPdfLink text={downloadText} />}
          <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} loading="" width={initialWidth} />
            ))}
          </Document>
          {downloadText && downloadTextPosition === 'bottom' && <DownloadPdfLink text={downloadText} />}
        </div>
      </div >
    </DialogContent >
  )
}

export default CustomManualPdf
