/* eslint-disable no-constant-condition */
/* eslint-disable testing-library/render-result-naming-convention */
/* eslint-disable indent */
import { FC, ReactText, useEffect, useRef, useState } from 'react'
// @ts-ignore
import * as mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
// @ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import LocalStorageTTL from '../../utilities/localStorage'
import EnterFlightData from './EnterFlightData'
import FlightPanel from './FlightPanel'
import MapOptionsPanel from './MapOptionsPanel'
import ZonesModal from '../views/ZonesModal/ZonesModal'
import CleanModal from '../views/CleanModal/CleanModal'
import axiosDroneMapClient from '../../apis/AxiosDroneMapClient'
import {
  getConflictZones,
  getNotams,
} from '../../utilities/requests'
import Loading from '../views/Loading/Loading'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { toast } from 'react-toastify'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
// const _ = require('lodash')
import {
  getNotamsPerZone,
  isZoneActiveNEW,
  metersToFeet,
  getWebCatActivationsPerZone,
  getTimeSlicesPerZone,
  asyncSleep,
} from '../../utilities/functions'
import { FlightData, Feature, FeatureLine } from '../../utilities/sharedInterfacesAndTypes'
import { selectLoader, setLoader } from '../../store/slices/loaderSlice/loaderSlice'
import { DroneEntry, DronesList, getDronesList, selectDrones } from '../../store/slices/dronesSlice/dronesSlice'
import { openUniversalModal } from '../../store/slices/universalModalSlice/universalModalSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectClean, setClean } from '../../store/slices/cleanSlice/cleanSlice'
import { useTranslation } from 'react-i18next'
import './Map.scss'
import moment from 'moment'
import { ActivationDetails } from '../../utilities/ActivationDetails'
import { GeolocateControlWrapper } from './changeGeolocateControl'
import layersData from './layers.json'

let currentMarkers: any = []

interface propsInterface {
  setRealLat: any
  setRealLon: any
  realLat: any
  realLon: any
}

const Map: FC<propsInterface> = ({
  setRealLat,
  setRealLon,
  realLat,
  realLon,
}) => {
  const { t } = useTranslation()
  mapboxgl.accessToken = 'pk.eyJ1IjoicGFuc2F1dG0iLCJhIjoiY2t2aTQ0NnNvMGxkYzMycXhsMmFxMDd6YiJ9.GhrkfQRKKn5WhcOXxzAgng'

  const mapContainer = useRef(null)
  const map = useRef<any>(null)

  const zones = useRef<Array<any>>([])
  const webCatActivations = useRef<Array<any>>([])
  const timeSlices = useRef<Array<any>>([])
  const notams = useRef<Array<any>>([])
  const zoneTypes = useRef<Array<any>>([])

  const [features, setFeatures] = useState<any>({})
  const [checkInMode, setCheckInMode] = useState('')
  const [openFlightData, setOpenFlightData] = useState(false)

  const [isCollapsedSearch, setIsCollapsedSearch] = useState<boolean>(true)

  const setOpenMenu = () => {
    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: true,
        modalType: 'menu',
        title: 'Menu',
        uniqueProps: {},
      })
    )
  }

  const setOpenApiMenu = () => {
    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: true,
        modalType: 'api_menu',
        title: 'API',
      })
    )
  }

  const setOpenKml = () => {
    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: true,
        modalType: 'kml',
        title: t('kml_download'),
      })
    )
  }

  const dispatch = useAppDispatch()
  const cleanOpen = useAppSelector(selectClean)
  const isLoading = useAppSelector(selectLoader)
  const dronesList = useAppSelector(selectDrones)

  const storedCheckInData = LocalStorageTTL.get('checkInData')
  const [checkZone, setCheckZone] = useState(false)
  const [flightData, setFlightData] = useState<FlightData>({
    range: storedCheckInData?.range || 100,
    max_height: storedCheckInData?.max_height || 120,
    start_time: storedCheckInData?.start_time || new Date(),
    duration: storedCheckInData?.duration || 30,
    mass: storedCheckInData?.mass || 200,
    flight_type: storedCheckInData?.flight_type || 'VLOS',
    flight_category: storedCheckInData?.flight_category || 'OPEN',
    flight_subcategory:
      storedCheckInData?.flight_subcategory || (storedCheckInData?.flight_category === 'CERTIFIED' ? '' : 'A1'),
    maxHeightAllowed: storedCheckInData?.maxHeightAllowed || 120,
  })
  const [heightToFilter, setHeightToFilter] = useState(60)

  const checkZoneRef = useRef(checkZone)
  const openZones = useRef(false)
  const isReady = useRef(false)
  const geoLocate = useRef<{
    on: (arg0: string, arg1: (e: { coords: { longitude: any; latitude: any } }) => void) => void
    off: (arg0: string) => void
    trigger: () => void
  } | null>(null)
  const geoLocateToastId = useRef<ReactText | null>(null);
  const flightDataRef = useRef(flightData)
  const zonesListRef = useRef<any>([])
  const dronesUpdateIntervalRef = useRef<any>()

  let dronesOnMap: {
    type: string
    properties: { data: any }
    geometry: { type: string; coordinates: number[] }
  }[] = []

  useEffect(() => {
    openZones.current = false
  }, [cleanOpen])

  useEffect(() => {
    if (isReady.current) {
      checkZoneRef.current = checkZone
      setCheckInMode('')
      if (checkZoneRef.current) {
        toast.info(t('click_check_zones'))
        map.current.getCanvas().style.cursor = 'crosshair'
        if (currentMarkers.length > 0) {
          openZones.current = false
          currentMarkers[0].remove()
        }
        currentMarkers = []
      } else {
        map.current.getCanvas().style.cursor = 'default'
      }
    }
  }, [checkZone, t])

  useEffect(() => {
    flightDataRef.current = flightData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightData])

  const updateDrones = async (droneList: DronesList) => {
    droneList.forEach((droneItem) => {
      const flyingDrone = find(dronesOnMap, {
        properties: {
          data: { location: { coordinates: [droneItem.geojson.coordinates[0], droneItem.geojson.coordinates[1]] } },
        },
      })
      if (!flyingDrone) {
        dronesOnMap.push({
          type: 'Feature',
          properties: {
            data: droneItem,
          },
          geometry: {
            type: 'Point',
            coordinates: [droneItem.geojson.coordinates[0], droneItem.geojson.coordinates[1], 100],
          },
        })
      }
    })
    const newDroneList: {
      type: string
      properties: { data: any }
      geometry: { type: string; coordinates: number[] }
    }[] = []
    dronesOnMap.forEach((elementOnMap) => {
      const flyingDroneOnMap = find(droneList, {
        geojson: {
          coordinates: [
            elementOnMap.properties.data.geojson.coordinates[0],
            elementOnMap.properties.data.geojson.coordinates[1],
          ],
        },
      })
      if (flyingDroneOnMap) {
        newDroneList.push(elementOnMap)
      }
    })
    dronesOnMap = newDroneList

    // avoid Race Condition. We wait until source drones is ready before we setData on it
    while (!map.current.getSource('drones')) {
      await asyncSleep(100)
    }
    map.current.getSource('drones').setData({
      type: 'FeatureCollection',
      features: dronesOnMap,
    })
  }

  const loadDrones = () => {
    map.current.loadImage('/drone_blue.png', (error: any, image: any) => {
      if (error) throw error
      map.current.addImage('custom-marker', image)
      map.current.addSource('drones', {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
        tolerance: 0,
        cluster: true,
        clusterMaxZoom: 12, // Max zoom to cluster points on
        clusterRadius: 50,
      })
      map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'drones',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      })
      map.current.getSource('drones').setData({
        type: 'FeatureCollection',
        features: dronesOnMap,
      })

      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'drones',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      })
      map.current.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'drones',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'custom-marker', // reference the image
          'icon-size': 0.25,
          'icon-allow-overlap': true,
        },
        paint: {
          'icon-opacity': 0.8,
        },
      })
      map.current.on('click', 'clusters', (e: { point: any }) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId = features[0].properties.cluster_id
        map.current.getSource('drones').getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
          if (err) return

          map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          })
        })
      })
      map.current.on(
        'click',
        'unclustered-point',
        (e: { features: { properties: any; geometry: any; id: number }[]; lngLat: { lng: number } }) => {
          const coordinates = e.features[0].geometry.coordinates.slice()

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }
          const drone: DroneEntry | undefined = JSON.parse(e.features[0].properties?.data)
          if (drone) {
            dispatch(
              openUniversalModal({
                isOpenModal: true,
                isCloseButtonVisible: true,
                modalType: 'info',
                title: t('drone_info'),
                uniqueProps: {
                  rowData: drone,
                  mapType: 'droneInfo',
                },
              })
            )
          }

        }
      )
      map.current.on('mouseenter', 'clusters', () => {
        map.current.getCanvas().style.cursor = 'pointer'
      })
      map.current.on('mouseleave', 'clusters', () => {
        map.current.getCanvas().style.cursor = ''
      })
      dispatch(getDronesList())
    })
  }

  useEffect(() => {
    map.current && updateDrones(dronesList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, dronesList])

  useEffect(() => {
    dispatch(setLoader(true))
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/pansautm/ckvrtmvh70elu14m39fju535m',
      center: [19.507472247470748, 51.757377418747375],
      zoom: 5.5,
      customAttribution: 'Map designed by PANSA',
      tileSize: 256,
    }).on('render', (e: any) => {
      if (e.target.getPitch() > 60) e.target.setPitch(59)
    })
    const geocoder = new MapboxGeocoder({
      flyTo: false,
      accessToken: mapboxgl.accessToken,
      marker: true,
      mapboxgl: mapboxgl,
    })

    geocoder.on('result', function (ev: any) {
      map.current.jumpTo({
        center: ev.result.center,
      })
    })

    geocoder.onAdd(map.current)
    geocoder.addTo('#geocoder-container')

    map.current.on('load', () => {
      map.current.resize()
      map.current.scrollZoom.setWheelZoomRate(1 / 1.01)
      let firstSymbolId = ''

      const layers: string[] = []
      layersData.reverse().forEach((item: { layer: { id: any; paint: any }; source: any }) => {
        map.current.addSource(item.layer.id, item.source)
        map.current.addLayer(item.layer, firstSymbolId)
        const lineColor = item.layer.id === 'RPA' ? ['get', 'color'] : item.layer.paint['fill-color'][2]

        map.current.addLayer(
          {
            id: item.layer.id + '_line',
            type: 'line',
            source: {
              promoteId: 'id',
              type: 'geojson',
              data: { type: 'FeatureCollection', features: [] },
            },
            paint: {
              'line-color': lineColor,
              'line-width': 1,
              'line-opacity': 0.5,
              'line-dasharray': [
                'match',
                ['get', 'isActive'],
                0,
                ['literal', [3, 5]],
                1,
                ['literal', []],
                ['literal', []],
              ],
            },
          },
          firstSymbolId
        )
        layers.push(item.layer.id)
        if (!firstSymbolId) {
          firstSymbolId = item.layer.id
        }
      })

      getZonesData()

      map.current.on('click', async (e: { lngLat: any; point: { properties: { name: any } }[] }) => {
        if (checkZoneRef.current) {
          dispatch(setLoader(true))
          if (currentMarkers.length > 0) {
            currentMarkers[0].setLngLat([e.lngLat.lng, e.lngLat.lat])
          } else {
            addMarker(e.lngLat.lat, e.lngLat.lng, 'flying')
          }
          const features = map.current.queryRenderedFeatures(e.point, { layers: layers })
          const latMarker = getMarker('lat')
          const lonMarker = getMarker('lng')
          const lat = currentMarkers.length ? latMarker : realLat
          const lng = currentMarkers.length ? lonMarker : realLon
          try {
            const conflictZones = await getConflictZones(flightDataRef.current, lat, lng)
            conflictZones && (openZones.current = true)
            const mergedZones = await getMergedZones(conflictZones, features)
            setFeatures(mergedZones)
            dispatch(setLoader(false))
          } catch (e) {
            dispatch(setClean(true))
          }
        } else {
          if (currentMarkers.length > 0) {
            openZones.current = false
            currentMarkers[0].remove()
          }
          currentMarkers = []
        }
        setCheckZone(false)
      })

      loadDrones()
      dronesUpdateIntervalRef.current = setInterval(() => {
        dispatch(getDronesList())
      }, 10000)
      isReady.current = true
    })

    // const debounceZonesLoading = _.debounce(function () {
    //   getZonesData({ layersList: null, height: 0, firstRun: false, locate: false })
    // }, 1500) to use when is needed to filter by area shown in user's screen
    // return () => {
    //   isReady.current = false
    //   map.current.remove()
    //   map.current = null
    //   clearInterval(dronesUpdateIntervalRef.current)
    //   dronesUpdateIntervalRef.current = null
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getZonesData = () => {
    return Promise.allSettled([
      axiosDroneMapClient.getAllZones(),
      axiosDroneMapClient.getWebCatActivations(),
      axiosDroneMapClient.getTimeSlices(),
      getNotams(),
      axiosDroneMapClient.getZoneTypes(),
      axiosDroneMapClient.getAupUupControl(),
    ])
      .then(([zonesRes, webCatActivationsRes, timeSlicesRes, notamsRes, zoneTypesRes, aupUupControl]) => {
        if (
          zonesRes.status != 'fulfilled' ||
          webCatActivationsRes.status != 'fulfilled' ||
          timeSlicesRes.status != 'fulfilled' ||
          notamsRes.status != 'fulfilled' ||
          zoneTypesRes.status != 'fulfilled' ||
          aupUupControl.status != 'fulfilled'
        ) {
          dispatch(setClean(true))
          return null
        }
        zones.current = zonesRes.value
        const uupAupControl = aupUupControl.value.control
        webCatActivations.current = webCatActivationsRes.value.filter((a: any) => a.etc.upType === uupAupControl)
        const webCatActivationsPerZone = getWebCatActivationsPerZone(webCatActivations.current)
        const timeSlicesPerZone = getTimeSlicesPerZone(timeSlicesRes.value)
        timeSlices.current = timeSlicesRes.value
        const notamsPerZone = getNotamsPerZone(notamsRes.value)
        notams.current = notamsRes.value
        zoneTypes.current = zoneTypesRes.value
        const featuresTemp: {
          type: string
          geometry: any
          properties: {
            source: string
            name: string
            color: string
            isActive: boolean
            data: { geojson: any; name: string; etc: { status: string }; type: string }
          }
        }[] = []

        const groupByTypeOfZone = zones.current.reduce(
          (
            acc: any,
            zone: {
              min: number
              max: number
              id: string
              acts: any
              geojson: any
              source: string
              name: string
              etc: { status: string; master: string }
              type: string
              start: string
              stop: string
              activity: ActivationDetails
              masterZone: any
            }
          ) => {
            // liczymy tylko dla mastera, a dzieci dziedziczą
            if (isEmpty(zone?.etc?.master)) {
              const activity = isZoneActiveNEW(
                {
                  zone: zone,
                  zoneNotams: notamsPerZone[zone.name] || [],
                  zoneActivations: webCatActivationsPerZone[zone.name] || [],
                  zoneTimeSlices: timeSlicesPerZone[zone.name] || [],
                },
                moment()
              )
              zone.activity = activity
            }

            const kind = zone.type
            acc[kind] = acc[kind] ?? []
            acc[kind].push(zone)
            return acc
          },
          {}
        )

        // jeśli strefa ma mastera podłączamy aktywność mastera i linkujemy pole masterZone
        forEach(zones.current, (v) => {
          const masterZoneName = v?.etc?.master

          if (!isEmpty(masterZoneName)) {
            const masterZone = find(zones.current, { name: masterZoneName })

            if (!isEmpty(masterZone)) {
              v.activity = masterZone.activity
              v.masterZone = masterZone
            } else {
              // console.warn(`Cannot find master zone ${masterZoneName} for - zone ${v.name}(${v.type}) is INACTIVE`, v)
              v.activity = ActivationDetails.NO_ACTIVATION
            }
          }
        })

        for (const key in groupByTypeOfZone) {
          const source = map.current.getSource(key)
          const lineSource = map.current.getSource(key + '_line')

          if (lineSource) {
            const featureListLine: FeatureLine[] = []
            groupByTypeOfZone[key].forEach(
              (z: {
                min: number
                max: number
                id: string
                acts: any
                geojson: any
                source: string
                name: string
                etc: { status: string }
                type: string
                start: string
                stop: string
                activity: ActivationDetails
                masterZone: any
              }) => {
                featuresTemp.push({
                  type: 'Feature',
                  geometry: { ...z.geojson, id: z.name + '_line' },
                  properties: {
                    source: key + '_line',
                    name: z.name + '_line',
                    color: z.etc && z.type === 'RPA' ? z.etc.status : '',
                    data: z,
                    isActive: z.activity.isActive,
                  },
                })
                // if (z.min <= heightToFilter) {
                featureListLine.push({
                  type: 'Feature',
                  id: z.name + '_line',
                  geometry: z.geojson,
                  properties: {
                    source: key + '_line',
                    isActive: +z.activity.isActive,
                    name: z.name + '_line',
                    id: z.name + '_line',
                    color: z.etc && z.type === 'RPA' ? z.etc.status : '',
                  },
                })
                // }
              }
            )
            lineSource.setData({
              type: 'FeatureCollection',
              features: featureListLine,
            })
          }

          if (source) {
            const featuresList: Feature[] = []
            groupByTypeOfZone[key].forEach(
              (z: {
                min: number
                max: number
                acts: any
                id: string
                geojson: any
                source: string
                name: string
                etc: { status: string }
                type: string
                start: string
                stop: string
                activity: ActivationDetails
              }) => {
                featuresTemp.push({
                  type: 'Feature',
                  geometry: z.geojson,
                  properties: {
                    source: z.type,
                    name: z.name,
                    color: z.etc && z.type === 'RPA' ? z.etc.status : '',
                    data: z,
                    isActive: z.activity.isActive,
                  },
                })

                // if (z.min <= heightToFilter) {
                featuresList.push({
                  type: 'Feature',
                  id: z.name,
                  geometry: z.geojson,
                  properties: {
                    source: z.type,
                    name: z.name,
                    color: z.etc && z.type === 'RPA' ? z.etc.status : '',
                    data: z,
                    id: z.name,
                    isActive: z.activity.isActive,
                  },
                })
                // }
              }
            )
            source.setData({
              type: 'FeatureCollection',
              features: featuresList,
            })
          }
        }

        zonesListRef.current = featuresTemp
        let interval: any = ''
        //@ts-ignore
        geoLocate.current = new GeolocateControlWrapper({})

        if (geoLocate.current) {
          geoLocate.current.on('error', (error: any) => {
            if (geoLocateToastId.current)
              toast.dismiss(geoLocateToastId.current)
            toast.error(t('geo_error'))
            // eslint-disable-next-line no-console
            console.log('geolocate ERROR!', error)
          })
          geoLocate.current.on('geolocate', (e: { coords: { longitude: any; latitude: any } }) => {
            if (geoLocateToastId.current)
              toast.dismiss(geoLocateToastId.current)
            toast.info(t('geo_finished'))
            setRealLat(e.coords.latitude)
            setRealLon(e.coords.longitude);
          })
        }

        map.current.addControl(geoLocate.current)
        interval = setInterval(() => {
          if (isReady.current) {
            let loaded = true
            for (const key in groupByTypeOfZone) {
              if (map.current.getSource(key) && !map.current.isSourceLoaded(key)) {
                loaded = false
              }
            }
            if (loaded) {
              zonesListRef.current.forEach((zone: any) => {
                if (zone.properties.source.indexOf('_line') === -1) {
                  map.current.setFeatureState(
                    {
                      source: zone.properties.source,
                      id: zone.properties.name,
                    },
                    {
                      isActive: zone.properties.isActive,
                    }
                  )
                } // else if (zone.properties.source.indexOf('_line') > 0) {
                //
                // will be used when needed, with autotupdate of features
                //
                // const features = cloneDeep(map.current.getSource(zone.properties.source)._data.features)
                // features.length &&
                // features.forEach((feature: { properties: { isActive: number } }) => {
                //     feature.properties.isActive = 0
                //   })
                //   features.length &&
                //   map.current
                //     .getSource(zone.properties.source)
                //     .setData({ type: 'FeatureCollection', features: features })
                //}
              })
              // options.firstRun && options.layersList && geolocateUser()

              clearInterval(interval)
              interval = null
              dispatch(setLoader(false))
            }
          }
        }, 250)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }

  const setMaxHeightAllowed = (maxHeight: number) => {
    setFlightData((prevFlightData) => {
      prevFlightData.maxHeightAllowed = maxHeight
      return prevFlightData
    })
  }

  const checkActivations = (zone: { properties: { name: string } }, zonesActivations: Array<any>) => {
    const zoneActivation = filter(zonesActivations, {
      designator: zone.properties.name,
    })
    return zoneActivation
  }

  const removeDroneRadarDesc = (description: string) => {
    if (description) {
      return description.replace(/ droneradar/gi, '')
    } else {
      return description
    }
  }

  const mergeMultiLangDescriptions = (zoneNotam: any, zone: any, zoneType: any) => {
    return {
      pl: removeDroneRadarDesc(
        zoneNotam?.description?.pl ??
        zone.properties.data?.description?.pl ??
        zoneType?.description?.pl ??
        ''
      ),
      en: removeDroneRadarDesc(
        zoneNotam?.description?.en ??
        zone.properties.data?.description?.en ??
        zoneType?.description?.en ??
        ''
      ),
    }
  }

  const getMergedZones = async (
    conflictZonesSrv: { conflictedZones: any; requirements: any; hgtElevation: number },
    mapZones: any
  ) => {
    const allZones: any[] = []

    const amslHeightFeet = metersToFeet(flightDataRef.current.max_height + conflictZonesSrv.hgtElevation)
    setFlightData((prevFlightData) => {
      prevFlightData.amslHeight = conflictZonesSrv.hgtElevation + prevFlightData.max_height
      return prevFlightData
    })
    if (conflictZonesSrv?.conflictedZones?.length || mapZones.length) {
      const notamsPerZone = getNotamsPerZone(notams.current)
      conflictZonesSrv.conflictedZones.forEach((zoneName: string) => {
        const zone = find(zonesListRef.current, { properties: { name: zoneName } })
        if (zone) {
          const zoneType = find(zoneTypes.current, { name: zone.properties.data?.type })
          const zoneNotam = notamsPerZone[zoneName] //znajduje jeden:?

          if (-1 === findIndex(allZones, { properties: { name: zone.properties.name } })) {
            const activation = checkActivations(zone, webCatActivations.current)
            if (activation) {
              zone.activation = activation
            }
            if (zone.properties?.data && zone.properties.data.min <= amslHeightFeet) {
              const zoneTemp = { ...cloneDeep(zone), ...cloneDeep(zoneType) }
              if (zoneNotam) {
                zoneTemp.properties.data.notam = zoneNotam
              }

              if (!zoneTemp.properties.data) {
                zoneTemp.properties.data = {}
              }

              zoneTemp.properties.data.description = mergeMultiLangDescriptions(zoneNotam, zone, zoneType)
              allZones.push(zoneTemp)
            }
          }
        }
      })
      mapZones.forEach(
        (zone: {
          min: number
          max: number
          type: string
          activation: any
          properties: { name: string; description: { pl: string }; data: any }
        }) => {
          if (!zone.properties?.data?.type) {
            zone.properties.data = zone.properties?.data ? JSON.parse(zone.properties.data) : null
          }
          const zoneType = find(zoneTypes.current, { name: zone.properties.data?.type })
          const zoneNotam = find(notamsPerZone, { zonename: zone.properties.name })
          if (-1 === findIndex(allZones, { properties: { name: zone.properties.name } })) {
            if (zone.properties.data.min <= amslHeightFeet) {
              const activation = checkActivations(zone, webCatActivations.current)
              if (activation) {
                zone.activation = activation
              }
              const zoneTemp = { ...cloneDeep(zone), ...cloneDeep(zoneType) }
              if (zoneNotam) {
                zoneTemp.properties.data.notam = zoneNotam
              }
              if (zone.properties.data?.description?.pl) {
                zoneTemp.properties.data.description = zone.properties.data.description
              } else {
                zoneTemp.properties.data.description = zoneType.description
              }
              allZones.push(zoneTemp)
            }
          }
        }
      )
    }
    return { zones: allZones, requirements: conflictZonesSrv?.requirements }
  }

  const getMarker = (option: string) => {
    return currentMarkers[0] && currentMarkers[0]._lngLat[option]
  }

  const geolocateMe = () => {
    setCheckZone(false)
    openZones.current = false
    if (geoLocate.current) {
      geoLocateToastId.current = toast.info(t('geo_in_progress'))
      geoLocate.current.trigger()
    }
  }

  const setHeadingNorth = () => {
    const camera = map.current.getFreeCameraOptions()
    camera.setPitchBearing(0, 0)
    map.current.setFreeCameraOptions(camera)
  }

  const setOpenZones = (open: boolean) => {
    openZones.current = open
  }

  const addMarker = (lat: any, long: any, id: string) => {
    const oneMarker = new mapboxgl.Marker().setLngLat([long, lat]).addTo(map.current)
    oneMarker.id = id
    currentMarkers.push(oneMarker)
  }

  return (
    <>
      <FlightPanel
        map={map}
        flightData={flightData}
        setFlightData={setFlightData}
        setOpenFlightData={setOpenFlightData}
        // getZonesByHeight={getZonesData}
        heightToFilter={heightToFilter}
        setHeightToFilter={setHeightToFilter}
      />
      <MapOptionsPanel
        setCheckZone={() => setCheckZone(true)}
        geolocateMe={geolocateMe}
        setOpenKml={setOpenKml}
        setOpenMenu={setOpenMenu}
        setHeadingNorth={setHeadingNorth}
        checkZone={checkZone}
        isCollapsedSearch={isCollapsedSearch}
        setIsCollapsedSearch={setIsCollapsedSearch}
        setOpenApiMenu={setOpenApiMenu}
      />
      <div ref={mapContainer} className="map-container" />
      {openFlightData && (
        <EnterFlightData
          open={openFlightData}
          setOpenFlightData={setOpenFlightData}
          flightData={flightData}
          commitFlightData={setFlightData}
          setMaxHeightAllowed={setMaxHeightAllowed}
          maxHeightAllowed={flightData.maxHeightAllowed}
        />
      )}
      <CleanModal />
      {!isLoading && openZones.current && (
        <ZonesModal
          isLoading={isLoading}
          open={openZones.current}
          features={features}
          lat={currentMarkers.length ? getMarker('lat') : realLat}
          lng={currentMarkers.length ? getMarker('lng') : realLon}
          max_height={flightData.max_height}
          max_heightAMSL={flightData.amslHeight}
          mass={flightData.mass}
          mode={checkInMode}
          bvlos={flightData.flight_type === 'BVLOS'}
          setOpenZones={setOpenZones}
        />
      )}
      {isLoading && <Loading />}
    </>
  )
}

export default Map
